<template>
  <PrimePage>
    <ion-content>
      <div class="prime-view-container fixed-full-height">
        <div class="prime-full-width-container">
          <h3 class="view-title">Event History</h3>
          <div v-if="isPageLoading" class="prime-empty-container margin-top-twenty">
            <ion-spinner name="lines"></ion-spinner>
          </div>
          <div v-else-if="!hasData" class="prime-empty-container full-height">
            <div v-if="!isTableLoading">
              <IconCheckCircle class="prime-tasks-empty-icon icon-check-circle-watermark" />
              <h5>There are no events to view.</h5>
            </div>
            <div v-else class="prime-empty-container margin-top-twenty">
              <ion-spinner name="dots"></ion-spinner>
            </div>
          </div>
          <div v-else class="prime-tasks-section-container">
            <div v-if="isTableLoading" class="text-center pad-ten loading-overlay center-center fade-in margin-top-ten">
              <ion-spinner name="dots"></ion-spinner>
            </div>
            <vue-good-table
              ref="vgt-table"
              mode="remote"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPageDropdown: [10, 20, 30, 40, 50],
                setCurrentPage: page,
                perPage: perPage,
                dropdownAllowAll: false
              }"
              :totalRows="totalRecords"
              :columns="columns"
              :rows="rows"
              styleClass="prime-tasks-table vgt-tasks-table"
              :sort-options="{
                enabled: true
              }"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              @on-sort-change="onSortChange"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.label == 'Patient'">
                  {{ (props.row.beneLastName ? props.row.beneLastName + ", " : "") + (props.row.beneFirstName ? props.row.beneFirstName : "") }}
                </span>
                <span v-if="props.column.label == 'Event ID'">
                  {{ props.row.id ? props.row.id : "" }}
                </span>
                <span v-if="props.column.label == 'Event Type'">
                  <div class="text-muted">
                    {{ props.row.eventTypeTitle ? props.row.eventTypeTitle : "" }}
                  </div>
                  <div class="text-bold">
                    {{ props.row.eventTypeName ? props.row.eventTypeName : "" }}
                  </div>
                </span>
                <span v-if="props.column.label == 'Created By'">
                  {{ makeProfessionalName(props.row) }}
                </span>
                <span v-if="props.column.label == 'Created'">
                  <!-- <span v-if="props.row.createdAt">{{ props.row.createdAt | moment.utc("M/DD/YYYY - h:mmA") }}</span> -->
                  <span v-if="props.row.createdAt">{{ formatEpochDate(props.row.createdAt) }}</span>
                  <span v-else>N/A</span>
                </span>
                <!-- <span v-else-if="props.column.label == 'Status'" class="text-center text-bold pill" :class="{ 'green-pill': props.row.task.status == 'completed', 'yellow-pill': props.row.task.status == 'in_progress', 'blue-pill': props.row.task.status == 'created' || props.row.task.status == 'assigned', 'red-pill': props.row.task.status == 'blocked' }">
                  {{ props.row.task.status ? makeStatus(props.row.task.status) : "N/A" }}
                </span> -->
                <span v-else-if="props.column.field == 'eventAction'">
                  <button @click="showActions($event, props.row)" class="prime-button button-block button-secondary">
                    {{ props.row.status == "active" ? "Cancel Event" : "Canceled - View Details" }}
                  </button>
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </ion-content>
  </PrimePage>
</template>

<script>
import PrimePage from "@/components/Global/PrimePage";
import IconCheckCircle from "@/components/Global/Icons/IconCheckCircle";
import PopoverPCPInfo from "@/components/Tasks/PopoverPcpInfo";
import ModalEventDescription from "@/components/Events/ModalEventDescription";
import ModalEventUpdate from "@/components/Events/ModalEventUpdate";
import { EventBus } from "@/services/Events";
import { send as httpSend } from "@/services/Api";

import { addIcons } from "ionicons";
import { arrowDown, arrowUp } from "ionicons/icons";

addIcons({
  "ios-arrow-up": arrowUp.ios,
  "ios-arrow-down": arrowDown.ios
});

export default {
  name: "EventHistory",
  components: {
    PrimePage,
    IconCheckCircle
  },
  data() {
    return {
      isPageLoading: false,
      isTableLoading: false,
      modalEventDescription: ModalEventDescription,
      modalEventUpdate: ModalEventUpdate,
      page: 1,
      perPage: 10,
      serverParams: {
        page: "1",
        perPage: "10",
        columnFilters: {},
        sort: [{ field: "createdAt", type: "desc" }]
      },
      columns: [
        {
          label: "Patient",
          field: "beneLastName",
          sortable: false,
          thClass: "prime-roster-nosort"
        },
        {
          label: "Event ID",
          field: "id",
          width: "100px",
          sortable: false,
          thClass: "prime-roster-nosort"
        },
        {
          label: "Event Type",
          field: "eventTypeTitle",
          width: "320px",
          sortable: true
        },
        {
          label: "Created By",
          field: "createdByLastName",
          width: "175px",
          sortable: true
        },
        {
          label: "Created",
          field: "createdAt",
          width: "140px",
          sortable: true
        },
        {
          label: "",
          field: "eventAction",
          thClass: "prime-roster-nosort",
          tdClass: "prime-roster-options-button-container",
          width: "225px",
          sortable: false
        }
      ],
      rows: [],
      totalRecords: 10,
      rowClickDisabled: false
    };
  },
  computed: {
    hasData() {
      return !this.isLoading && this.rows.length > 0;
    }
  },
  created() {
    this.getEventHistory(this.serverParams, false, true);

    EventBus.$on("getEventHistory", () => {
      this.getEventHistory(this.serverParams, false, true);
    });
  },
  methods: {
    formatEpochDate(epDate) {
      let date = new Date(Number(epDate));
      return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
    },
    showActions(ev, rowData) {
      this.$ionic.modalController
        .create({
          component: rowData.status == "active" ? ModalEventUpdate : ModalEventDescription,
          event: ev,
          mode: "ios",
          cssClass: "popover-width-sm",
          componentProps: {
            data: { eventData: rowData }
          }
        })
        .then(p => p.present());
    },
    makeProfessionalName(rowObj) {
      let title = rowObj.createdByTitle;
      let fName = rowObj.createdByFirstName;
      let lName = rowObj.createdByLastName;
      return (fName ? fName : "") + (lName ? " " + lName : "") + (title ? ", " + title : "");
    },
    goToNamedRoute(routeName) {
      if (this.$route.query.backId) {
        this.$router.push({
          name: routeName,
          params: { id: this.$route.query.backId }
        });
      } else {
        this.$router.push({
          name: routeName
        });
      }
    },
    getEventHistory(params, showPageLoading, showTableLoading) {
      showPageLoading ? (this.isPageLoading = true) : "";
      showTableLoading ? (this.isTableLoading = true) : "";

      this.isTableLoading = true;
      const method = "post";
      const path = document.config.eventHistory;
      const payload = params;

      httpSend({ path, method, payload, authToken: true })
        .then(response => {
          this.totalRecords = response.data.totalRecords;
          this.rows = response.data.results;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Failed to load Event History",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },
    onPageChange(params) {
      this.serverParams.page = this.page = params.currentPage;
      this.getEventHistory(this.serverParams, false, true);
    },
    onPerPageChange(params) {
      this.serverParams.perPage = this.perPage = params.currentPerPage;
      this.getEventHistory(this.serverParams, false, true);
    },
    onSortChange(params) {
      this.serverParams.sort = [{ field: params[0].field, type: params[0].type }];
      this.getEventHistory(this.serverParams, false, true);
    },
    openPatientInfoPopover(ev, rowObj) {
      this.rowClickDisabled = true;
      this.$ionic.popoverController
        .create({
          component: PopoverPCPInfo,
          event: ev,
          mode: "ios",
          componentProps: {
            data: {
              propData: rowObj
            }
          }
        })
        .then(m => m.present());
    }
  }
};
</script>

<style scoped>
/* -------------------------------- */
/* Tasks View --------------------- */
/* -------------------------------- */

.prime-empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
}

.prime-empty-container ion-spinner {
  width: 200px;
  background: var(--ion-color-light);
  border-radius: 6px;
}

.prime-empty-container .prime-tasks-empty-icon,
.prime-empty-container h5 {
  opacity: 0.1;
}

.prime-tasks-section-list {
  margin-bottom: 50px;
}
.prime-tasks-section-header {
  margin-bottom: 10px;
}
.prime-tasks-section-header h5 {
  margin: 0 0 0 10px !important;
}
.prime-tasks-section-header .prime-tasks-column-header {
  font-size: 14px;
  color: var(--ion-color-medium-shade);
  text-align: center;
}
.prime-tasks-section-header h5.prime-overdue-section-header {
  color: white;
  background: var(--ion-color-danger-tint);
  display: inline-block;
  padding: 2px 10px;
  border-radius: 6px 6px 0 0;
  border-bottom: 8px solid var(--ion-color-danger-tint);
  margin-bottom: -32px !important;
  margin-left: 10px !important;
}

/* Tasks Columns Set Width ---------------- */
.prime-tasks-column-created {
  max-width: 80px;
  min-width: 80px;
}
.prime-tasks-column-due-date {
  min-width: 100px;
  flex-shrink: 0;
  flex-grow: 0;
}
.prime-tasks-column-button {
  max-width: 40px;
  min-width: 40px;
}
.prime-tasks-column-assigned-to {
  min-width: 180px;
  flex-shrink: 0;
}

/* Due Date Input ---------------- */
.prime-tasks-card input {
  margin-bottom: 0;
}
.prime-tasks-card .prime-tasks-due-date-input {
  border: none;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: var(--ion-color-primary);
  cursor: pointer;
  padding: 7px 5px 7px;
}
.prime-tasks-card .prime-tasks-due-date-input:hover,
.prime-tasks-card .prime-tasks-due-date-input:focus {
  border: none;
  outline: none;
  background: var(--ion-color-light);
}
.vc-highlight {
  background: var(--ion-color-primary);
}
.vc-container {
  font-family: var(--ion-font-family);
}
.icon-check-circle-watermark {
  width: 140px;
}

/* vgt overrides */

.vgt-responsive {
  font-size: 14px;
  border-collapse: collapse;
  background-color: rgba(255, 255, 255, 0) !important;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
  border: 1px solid;
}

table.vgt-table tr.clickable {
  cursor: pointer;
}

table.vgt-table tr.clickable:hover {
  background-color: var(--ion-color-light);
}

.vgt-table th {
  padding: 10px 5px;
  vertical-align: middle;
  position: relative;
}

.vgt-table th.line-numbers,
.vgt-table th.vgt-checkbox-col {
  padding: 0 0.75em 0 0.75em;
  color: #606266;
  border-right: 1px solid #dcdfe6;
  word-wrap: break-word;
  width: 25px;
  text-align: center;
  background: linear-gradient(#f4f5f8, #f1f3f6);
}

.vgt-table th.filter-th {
  padding: 0.75em 0.75em 0.75em 0.75em;
}

.vgt-table th.vgt-row-header {
  border-bottom: 2px solid #dcdfe6;
  border-top: 2px solid #dcdfe6;
  background-color: #fafafb;
}

.vgt-table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dcdfe6;
  user-select: none;
  font-size: 13px;
}

.vgt-table thead th.vgt-checkbox-col {
  vertical-align: middle;
}

/* task card styling */
.prime-tasks-title-container {
  padding-left: 10px;
  display: flex;
  align-items: center;
}
.prime-tasks-title-container .prime-tasks-task-title-input {
  margin-left: 15px;
  width: 100%;
  height: 34px;
  border: 2px solid #ffffff;
  border-radius: 8px;
  padding: 6px 15px 7px 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: border 0.2s ease-in-out;
}
.prime-tasks-title-container .prime-tasks-task-title-input:hover {
  background: var(--ion-color-light);
  border: 2px solid var(--ion-color-light);
  cursor: pointer;
}
.prime-tasks-title-container .prime-tasks-task-title-input:focus {
  background: #ffffff;
  border: 2px solid var(--ion-color-primary);
  cursor: initial;
}

/* Task Checkbox ---------------- */
.prime-task-checkbox {
  height: 24px;
  width: 24px;
  display: block;
  position: relative;
}
.prime-task-checkbox div {
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  cursor: pointer;
}
.prime-task-checkbox div::before,
.prime-task-checkbox div::after {
  content: "";
  display: block;
  transition: all 0.2s ease-in-out;
}
.prime-task-checkbox div::before {
  height: 20px;
  width: 20px;
  border: 2px solid var(--ion-color-medium-shade);
  border-radius: 20px;
  background: #ffffff;
  cursor: pointer;
}
.prime-task-checkbox div:hover::before {
  border: 2px solid var(--ion-color-primary);
}
.prime-task-checkbox div::after {
  height: 5px;
  width: 10px;
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  transform: rotate(-45deg);
  position: absolute;
  position: absolute;
  top: 7px;
  left: 6px;
}
.prime-task-checkbox.checked div:before {
  background: var(--ion-color-primary);
  border: 2px solid var(--ion-color-primary);
}

.prime-task-checkbox.checkedGrey div:before {
  background: var(--ion-color-medium-shade);
  border: 2px solid var(--ion-color-medium-shade);
}

.prime-tasks-section-container {
  position: relative;
}
</style>
