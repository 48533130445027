<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <h5 slot="start">Cancel Event</h5>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-modal-content" color="light">
      <div v-if="stepNumber == 1">
        <ion-card @click="stepNumber = 2" color="white" class="prime-patient-card">
          <ion-row class="ion-align-items-center cursor-pointer">
            <ion-col size="auto">
              <IconPerson class="icon-primary icon-xlarge" />
            </ion-col>
            <ion-col class="margin-left-twenty">
              <p class="text-bold">Cancel Individual Patient's Event</p>
            </ion-col>
          </ion-row>
        </ion-card>
        <ion-card @click="(isBulkEvent = true), (stepNumber = 2)" v-if="eventData.batchId" class="prime-patient-card">
          <ion-row class="ion-align-items-center cursor-pointer">
            <ion-col size="auto">
              <IconPeople class="icon-primary icon-xlarge" />
            </ion-col>
            <ion-col class="margin-left-twenty">
              <p class="text-bold">Cancel Bulk Event for All Patients</p>
            </ion-col>
          </ion-row>
        </ion-card>
      </div>
      <div v-else-if="stepNumber == 2">
        <ion-row>
          <ion-col>
            <p>Note: Canceling this event will prevent it from triggering any further actions in the system, but cannot recall any actions already triggered.</p>
            <p class="margin-top-twenty">Please provide a reason for canceling this Event:</p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <textarea class="form-control margin-top-twenty" rows="5" v-model="reasonTxt"></textarea>
          </ion-col>
        </ion-row>
      </div>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row class="ion-align-items-center pad-lr-twenty pad-top-ten pad-bottom-ten">
          <ion-col>
            <button @click="closeModal()" class="prime-button button-secondary button-block">
              Close
            </button>
          </ion-col>
          <ion-col v-if="stepNumber == 2">
            <button @click="cancelEvent()" class="prime-button button-primary button-block">
              Cancel Event
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { more } from "ionicons/icons";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events";
import IconPeople from "@/components/Global/Icons/IconPeople";
import IconPerson from "@/components/Global/Icons/IconPerson";

addIcons({
  "md-more": more.md
});
export default {
  name: "ModalEventUpdate",
  components: {
    IconPeople,
    IconPerson
  },
  data() {
    return {
      stepNumber: 1,
      reasonTxt: undefined,
      isBulkEvent: false,
      payload: {
        correlationId: undefined,
        recallReason: ""
      }
    };
  },
  methods: {
    cancelEvent() {
      const method = "post";
      const path = document.config.cancelEvent;
      // populate payload from props passed from parent component
      this.reasonTxt ? (this.payload.recallReason = this.reasonTxt) : "";
      this.payload.correlationId = this.eventData.correlationId;
      this.isBulkEvent ? (this.payload.batchId = this.eventData.batchId) : "";
      const payload = this.payload;
      httpSend({ path, method, payload, authToken: true })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "The cancellation request has been entered",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());

          this.closeModal();
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not get cancel event. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.closeModal();
          EventBus.$emit("getEventHistory");
        });
    },
    closeModal: function() {
      this.$ionic.modalController.dismiss({ description: null });
    }
  }
};
</script>

<style scoped>
ion-textarea {
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 8px;
  padding: 12px 15px;
  font-size: 14px;
  font-family: var(--ion-font-family);
  font-weight: 500;
  line-height: 1.5;
  transition: border 0.2s ease-in-out;
}
ion-textarea textarea {
  padding: 0 !important;
}
ion-textarea:hover {
  background: var(--ion-color-light);
}
ion-textarea:focus {
  background: #ffffff;
  border: 2px solid var(--ion-color-primary);
}

ion-footer {
  padding: 20px;
}
ion-footer ion-col:first-child {
  padding-right: 5px;
}
ion-footer ion-col:not(:first-child) {
  padding-left: 5px;
}
.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}

.column-stretch-header {
  flex: 0 0 auto;
}

.column-stretch-body {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow: hidden;
}

.column-stretch-footer {
  flex: 0 0 auto;
}

.pad-lr-five {
  padding-right: 5px;
  padding-left: 5px;
}
</style>
